import React, {Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import Loader from 'react-loader-spinner';
import Loader from './Loader/Loader'
import './scss/style.scss';

const loading = (
  <Loader/>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const Home = React.lazy(() => import('./component/home/home'));
const UserLogin = React.lazy(() => import('./component/profile/login'));
const UserRegister = React.lazy(() => import('./component/profile/register'));
const VendorRegister = React.lazy(() => import('./component/profile/vendor-register'));
const VendorActivate = React.lazy(() => import('./component/profile/vendor-activate'));
const UserBooking = React.lazy(() => import('./component/auth/appointments/booking'));
const UserAvailability = React.lazy(() => import('./component/auth/appointments/availability'));
const OpenHouse = React.lazy(() => import('./component/auth/openHouse/openHouse'));
const OpenHouseList = React.lazy(() => import('./component/auth/openHouse/openHouseList'));
const UserAppointment = React.lazy(() => import('./component/auth/appointments/appointment'));
const UserAppointmentReschedule = React.lazy(() => import('./component/auth/appointments/appointmentReschedule'));
const UserAppointmentConfirm = React.lazy(() => import('./component/auth/appointments/appointment-confirm'));
const UserDashboard = React.lazy(() => import('./component/auth/dashboard/dashboard'));
// const UserCategory = React.lazy(() => import('./component/auth/category'));
const StoresList = React.lazy(() => import('./component/stores/storesList'));
const VendorStore = React.lazy(() => import('./component/auth/vendorStores/vendor-stores'));
const AddVendorStore = React.lazy(() => import('./component/auth/vendorStores/newStore'));
const EditVendorStore = React.lazy(() => import('./component/auth/vendorStores/editVendorStore'));
const AddProduct = React.lazy(() => import('./component/auth/vendorStores/addProduct'));
const ReportIssue = React.lazy(() => import('./component/misc/reportIssue'));
const NewVendorForm = React.lazy(() => import('./component/misc/newVendorForm'));
const UpdateProfile = React.lazy(() => import('./component/profile/updateProfile'));
const OrderStatus = React.lazy(() => import('./component/auth/order/orderStatus'));
const ViewDetails = React.lazy(() => import('./component/stores/viewDetails'));
const TermsOfUse = React.lazy(() => import('./component/misc/termsOfUse'));
const PrivacyPolicy = React.lazy(() => import('./component/misc/privacyPolicy'));
const Faqs = React.lazy(() => import('./component/misc/faqs'));
const ShoppingBag = React.lazy(() => import('./component/auth/order/shoppingBag'));
const OrderConfirmation = React.lazy(() => import('./component/auth/order/orderConfirmation'));
const SubscriptionPlans = React.lazy(() => import('./component/misc/subscriptionPlans'));
const ProductDetails = React.lazy(() => import('./component/stores/productDetails'));
const ManageProducts = React.lazy(() => import('./component/auth/vendorStores/manageStoreProducts'));
const ManageGallery = React.lazy(() => import('./component/auth/vendorStores/manageStoreGallery'));
const EditProduct = React.lazy(() => import('./component/auth/vendorStores/editProduct'));
const SalesContact = React.lazy(() => import('./component/misc/salesContact'));
const AboutVshop = React.lazy(() => import('./component/misc/aboutVshop'));
const S3Uploader = React.lazy(() => import('./views/objectRepository/s3Uploader'));
const S3FolderDeleter = React.lazy(() => import('./views/objectRepository/s3FolderDeleter'));
const S3FileDownloader = React.lazy(() => import('./views/objectRepository/s3FileDownloader'));
const ObjectRepository = React.lazy(() => import('./views/objectRepository/objectRepository'));
const Unsubscribe = React.lazy(() => import('./component/misc/unsubscribe'));
const EditPreferences = React.lazy(() => import('./component/misc/editPreferences'));
const HowItWorks = React.lazy(() => import('./component/misc/howItWorks'));
const StoreProducts = React.lazy(() => import('./component/stores/storeProducts'));

class App extends Component {
  render() {
    
    return (
      
      <Router>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/" name="Home" render={props => <Home {...props}/>} />
              <Route exact path="/user/dashboard" name="User Dashboard" render={props => <UserDashboard {...props}/>} />
              <Route exact path="/stores/:id" name="Stores List" render={props => <StoresList {...props}/>} />
              <Route exact path="/user/vendor-stores/:id" name="Vendor Stores" render={props => <VendorStore {...props}/>} />
              <Route exact path="/user/update-profile/:id" name="Update Profile" render={props => <UpdateProfile {...props}/>} />
              <Route exact path="/user/order-status" name="Order Status" render={props => <OrderStatus {...props}/>} />
              <Route exact path="/user/edit-vendor-store/:id" name="Vendor Stores" render={props => <EditVendorStore {...props}/>} />
              <Route exact path="/user/add-product/:id" name="Add Product" render={props => <AddProduct {...props}/>} />
              <Route exact path="/product-details/:id" name="Vendor Stores" render={props => <ProductDetails {...props}/>} />
              <Route exact path="/user/manage-products/:id" name="Manage Products" render={props => <ManageProducts {...props}/>} />
              <Route exact path="/user/manage-gallery/:id" name="Manage Gallery" render={props => <ManageGallery {...props}/>} />
              <Route exact path="/user/edit-product/:id" name="EditProduct" render={props => <EditProduct  {...props}/>} />

              <Route exact path="/user/add-store/:id" name="Vendor Store Add " render={props => <AddVendorStore {...props}/>} />
              <Route exact path="/user/appointment-confirm/" name="Appointment confirm" render={props => <UserAppointmentConfirm {...props}/>} />
              <Route exact path="/user/appointment/:id" name="Schedule Appointment" render={props => <UserAppointment {...props}/>} />
              <Route exact path="/user/appointment-reschedule/:id" name="Reschedule Appointment" render={props => <UserAppointmentReschedule {...props}/>} />
              {/* <Route exact path="/user/category" name="User Category" render={props => <UserCategory {...props}/>} /> */}
              <Route exact path="/user/availability" name="Manage Availability" render={props => <UserAvailability {...props}/>} />
              <Route exact path="/user/openHouse" name="Manage Openhouse" render={props => <OpenHouse {...props}/>} />
              <Route exact path="/user/openHouseList" name="Manage Openhouse List" render={props => <OpenHouseList {...props}/>} />
              <Route exact path="/user/booking" name="Booking" render={props => <UserBooking {...props}/>} />
              <Route exact path="/user/vendor-register" name="Vendor Register" render={props => <VendorRegister {...props}/>} />
              <Route exact path="/user/vendor-activate" name="Vendor Activate" render={props => <VendorActivate {...props}/>} />
              <Route exact path="/user/register" name="User Register" render={props => <UserRegister {...props}/>} />
              <Route exact path="/user/login" name="User Login" render={props => <UserLogin {...props}/>} />
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route path="/dashboard" name="Home" render={props => <TheLayout {...props}/>} />
              <Route exact path="/users" name="Users" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/services" name="Services" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/users/addUser" name="Add Users" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/users/editUser/:id" name="Edit User" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/users/:id" name="User Details" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/services/addService" name="Add Services" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/services/:id" name="Service Details" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/services/editService/:id" name="Edit Service" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/vendors" name="Vendors" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/vendors/addVendors" name="Add Vendors" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/vendors/editVendor/:id" name="Edit Vendors" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/vendors/:id" name="Vendor Details" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/stores" name="Stores" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/stores/addStore" name="Add Stores" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/stores/storeBookings/:id" name="Store Bookings" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/stores/:id" name="Store" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/stores/editStore/:id" name="Edit Store" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/offers" name="Offers" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/offers/addOffers" name="Add Offers" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/offers/editOffer/:id" name="Edit Offer" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/offers/:id" name="Offer" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/store-detail/:id" name="Store Details" render={props => <ViewDetails  {...props}/>} />
              <Route exact path="/brands" name="Brands" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/brands/addBrand" name="Add Brands" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/brands/editBrand/:id" name="Edit Brand" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/brands/:id" name="Brand" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/banners/addBanner" name="Add Banner" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/banners" name="Banners" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/banners/editBanner/:id" name="Edit Banner" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/banners/:id" name="Banner" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/cmspages" name="Cms Pages" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/cmspages/addPage" name="Add Cms Page" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/cmspages/editPage/:id" name="Edit Page" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/cmspages/:id" name="Page" render={props => <TheLayout  {...props}/>} />
              <Route exact path="/terms-of-use" name="Terms Of Use" render={props => <TermsOfUse {...props}/>} />
              <Route exact path="/privacy-policy" name="Privacy Policy" render={props => <PrivacyPolicy {...props}/>} />
              <Route exact path="/faqs" name="Frequently Asked Questions" render={props => <Faqs {...props}/>} />
              <Route exact path="/features" name="Features" render={props => <TheLayout  {...props} />} />
              <Route exact path="/features/addFeatures" name="Add Features" render={props => <TheLayout  {...props} />} />
              <Route exact path="/features/editFeature/:id" name="Edit Features" render={props => <TheLayout  {...props} />} />
              <Route exact path="/features/:id" name="Feature Details" render={props => <TheLayout  {...props} />} />
              <Route exact path="/user/reportIssue" name="Report Issue" render={props=><ReportIssue {...props}/>}/>
              <Route exact path="/newVendorForm" name="Vendor Form" render={props=><NewVendorForm {...props}/>}/>
              <Route exact path="/reportIssues" name="Report Issue" render={props => <TheLayout  {...props} />} />
              <Route exact path="/reportIssues/:id" name="Issue Details" render={props => <TheLayout  {...props} />} />
              <Route exact path="/shoppingBag" name="Shopping Bag" render={props => <ShoppingBag  {...props}/>} />
              <Route exact path="/orderConfirmation" name="OrderConfirmation" render={props => <OrderConfirmation  {...props}/>} />
              <Route exact path="/subscriptionPlans" name="SubscriptionPlans" render={props => <SubscriptionPlans  {...props}/>} />
              <Route exact path="/productDetails" name="ProductDetails" render={props => <ProductDetails  {...props}/>} />
              <Route exact path="/salesContact" name="SalesContact" render={props => <SalesContact  {...props}/>} />
              <Route exact path="/aboutVshop" name="aboutVshop" render={props => <AboutVshop  {...props}/>} />
              <Route exact path="/objectRepository" name="objectRepository" render={props => <ObjectRepository  {...props}/>} />              
              <Route exact path="/s3Uploader" name="s3Uploader" render={props => <S3Uploader  {...props}/>} />
              <Route exact path="/s3FolderDeleter" name="s3FolderDeleter" render={props => <S3FolderDeleter  {...props}/>} />
              <Route exact path="/s3FileDownloader" name="s3FileDownloader" render={props => <S3FileDownloader  {...props}/>} />
              <Route exact path="/unsubscribe/:id" name="Unsubscribe" render={props => <Unsubscribe  {...props}/>} />
              <Route exact path="/edit-preferences/:id" name="EditPreferences" render={props => <EditPreferences  {...props}/>} />
              <Route exact path="/howItWorks" name="howItWorks" render={props => <HowItWorks  {...props}/>} />
              <Route exact path="/store-products/:id" name="StoreProducts" render={props => <StoreProducts  {...props}/>} />

            </Switch>
          </React.Suspense>
        </Router>
      
    );
  }
}

export default App;
  