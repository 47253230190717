import React from "react";
import './Loader.css';

const Loader = () => {
	return (
		// <div className="drip"></div>

		<div className="custom-loader">
      <img
        src={window.location.origin +"/assets/images/logo.png"}
        alt="Loading..."
        className="custom-image"
      />
      <p>Loading...</p>
    </div>
	);
}

export default Loader;